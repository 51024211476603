<template>
  <div>
    <!-- <b-row class="match-height"> -->
    <!-- <div class="col-xl-3 col-md-3 col-sm-6" v-for="data in setupdata"> -->
    <!-- <b-col>
          <statistic-card-vertical
            :icon="data.icon"
            :statistic="data.title"
            color="primary"
            :route="data.route"
          />
        </b-col> -->
    <b-row class="kb-search-content-info match-height">
      <b-col
        v-for="item in kb.knowledgeBase"
        :key="item.id"
        md="4"
        sm="6"
        class="kb-search-content"
      >
        <b-card
          class="text-center cursor-pointer"
          :img-src="item.img"
          :img-alt="item.img.slice(5)"
          img-top
          @click="RouterClicked(item.routerPath)"
        >
          <h2>{{ item.title }}</h2>
          <!-- <b-card-text class="mt-1">
            {{ item.desc }}
          </b-card-text> -->
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            statistic="36.99k"
            statistic-title="Views"
            color="info"
          />
        </b-col>
        <b-col xl="3" md="4" sm="6">
          <statistic-card-vertical
            icon="EyeIcon"
            statistic="36.99k"
            statistic-title="Views"
            color="info"
          />
        </b-col> -->
    <!-- </div> -->
    <!-- </b-row> -->
  </div>
</template>
<script>
import kb from '@/@fake-db/data/pages/setup-data.js'
// import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: "",

    kb,
      // setupdata: []
    };
  },
  mounted() {
    console.log()
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
  },
  created() {
    // this.$http.get("data/setup-data").then((res) => {
    //   this.kb = res.data;
    // });
    // this.$http.get('data/setup').then((res) => {
    //   this.setupdata = res.data
    // })
  },
  methods: {
    RouterClicked(routerData) {
      this.$router.push(`${routerData}`);
    },
    handleRoute() {
      this.$route.push("/");
    },
  },
};
</script>
